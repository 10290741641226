import React             from "react";
import {
  StyledLoginForm,
  StyledLoginFormLayout,
  StyledLoginFormWrapper,
  StyledLoginLogo
}                       from "./styles";
import {LogoDesktop}    from "../../../../icons/logo/LogoDesktop";
import Contacts
                        from "../../../../components/layout/leftMenu/components/contacts";
import {Heading1}       from "../../../../globalStyledComponents";
import {useHistory} from "react-router";
import RecoveryPassword        from "../recoveryPassword";
import LoginDefault     from "./components/loginDefault";
import LoginClient      from "./components/loginClient";

const LoginFormLayout = ({children}: { children: any }) => {
  const history = useHistory();
  return (
    <StyledLoginFormWrapper>
      <StyledLoginFormLayout>
        <StyledLoginLogo onClick={() => history.push("/")}>
          <LogoDesktop/>
        </StyledLoginLogo>
        {children}
        <Contacts isAuth={true}/>
      </StyledLoginFormLayout>
    </StyledLoginFormWrapper>
  )
};

export const LoginForm = () => {
  const {location} = useHistory();
  const isClient: boolean = location.pathname === "/authorization/client";

  const [stateRecoveryPassword, setRecoveryPassword] = React.useState<boolean>(false);

  if (stateRecoveryPassword) return (
    <LoginFormLayout>
      <RecoveryPassword setRecoveryPassword={setRecoveryPassword}/>
    </LoginFormLayout>);

  return (
    <LoginFormLayout>
      <StyledLoginForm>
        <Heading1>Авторизация</Heading1>
        {!isClient && <LoginDefault /*setRecoveryPassword={setRecoveryPassword}*//>}
        {isClient && <LoginClient setRecoveryPassword={setRecoveryPassword}/>}
      </StyledLoginForm>
    </LoginFormLayout>
  )
};

export default LoginForm;