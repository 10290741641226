import React              from 'react';
import {
  Route,
  Switch
}                         from 'react-router-dom';
import {Layout}           from "./components/layout";
import Authorization      from "./pages/authorization";
import RootRedirect       from "./components/rootRedirect";
import StatusNotification from "./components/statusNotification";

export const App = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path="/authorization" component={Authorization}/>
        <Route exact path="/authorization/client" component={Authorization}/>
        <Route component={RootRedirect}/>
      </Switch>
      <StatusNotification/>
    </Layout>
  )
}

export default App;
